export const MarketingCampaignConfig = {
  api: {
    // get
    index: '/dashboard/campaigns/rider-campaigns/',
    // post
    create: '/dashboard/campaigns/rider-campaigns/',
    // patch
    update: (id = 'uuid') => {
      return `/dashboard/campaigns/rider-campaigns/${id}/`
    },
    pushVoucher: (id = 'uuid') => {
      return `/dashboard/campaigns/rider-campaigns/${id}/push/`
    },
    detail: (id = 'uuid') => {
      return `/dashboard/campaigns/rider-campaigns/${id}/`
    },
    history: (id = 'uuid') => {
      return `/dashboard/campaigns/rider-campaigns/${id}/push-history/`
    },
  },
  events: {
    name: 'campaigns',
    // refresh-index-data
    refresh: `rid-campaign`,
    // slideover-right
    sorId: 'campaign',
    sorOpen: 'sor-open-campaign',
    sorClose: 'sor-close-campaign',
    sorToggle: 'sor-toggle-campaign',
    // editing-data
    editingData: 'edit-campaign-data',
    // viewing-data
    viewingData: 'view-campaign-data',
  },
}
