<template>
  <div class="block">
    <div class="voucher-card flex items-center justify-center overflow-hidden">
      <div class="vouchar-card-body flex items-center w-full">
        <div class="w-full px-12">
          <h4 class="font-bold text-lg">{{ title }}</h4>

          <h6 class="font-normal mt-4">{{ description }}</h6>
        </div>
      </div>
      <div class="v-bottom-right-icon overflow-hidden -bottom-12 -right-12">
        <div class="w-28 h-28 rounded-full v-circle"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CampaignCard',
  components: {},
  data() {
    return {
      isLoading: false,
      rd_color: 'rd_color',
      fd_color: 'fd_color',
      fr_color: 'fr_color',
      rd_bg: 'rd_bg',
      fd_bg: 'fd_bg',
      fr_bg: 'fr_bg',
    }
  },
  props: {
    title: {
      required: false,
    },
    description: {
      required: false,
    },
  },
  mounted() {
    console.log('data')
  },
}
</script>

<style lang="scss" scoped>
.voucher-card {
  width: 100%;
  height: 100%;
  min-height: 200px;
  background: #e0f8e6;
  border-radius: 22px;
  position: relative;
  .promo-right-icon {
    width: 180px;
    height: 55px;
    background: #ee9200;
    position: absolute;
    right: -3px;
    top: -3px;
    border-top-right-radius: 22px;
    border-bottom-left-radius: 60px;
    display: flex;
    span {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      font-family: Roboto;
    }
  }
  .vouchar-card-body {
    h3 {
      font-family: Roboto;
      font-size: 37px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #0d0d0d;
    }
    span {
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
    }
  }
}
.rd_color {
  border-color: #ee9200 !important;
}
.fr_color {
  border-color: #a414f2 !important;
}
.fd_color {
  border-color: #0093ee !important;
}
.rd_bg {
  background: #ee9200 !important;
}
.fr_bg {
  background: #a414f2 !important;
}
.fd_bg {
  background: #0093ee !important;
}
.v-bottom-right-icon {
  position: absolute;
}
.v-circle {
  border: 37px solid #0dff99;
  background: none;
}
</style>
